<template>
  <div class="position-relative mb-3 w-100">
    <div :class="['text-editor-custom', { error: isValidate }]">
      <div class="content-between">
        <label>
          {{ textFloat }}
          <span v-if="isRequired" class="text-error">*</span>
          <slot name="label-action"></slot>
        </label>
      </div>

      <OtherLoading v-if="isEdit && isLoading" />
      <template v-if="display">
        <textarea :id="name" v-model="editContent"></textarea>

        <TicketTemplate
          :ref="name"
          v-if="showTemplate"
          @selectTemplate="selectTemplate"
          @onAddFileAttachment="addFileAttachment"
          :templateId="templateId"
          :addFile="addFile"
        />
      </template>

      <div v-else class="card card-preview-editor p-3">
        <div class="edit-detail" @click="showEditor">
          <font-awesome-icon icon="pencil-alt" />
        </div>
        <div v-html="value" v-if="value"></div>
        <div v-else class="content-empty">No Information</div>
      </div>
    </div>

    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <div v-if="v && v.$error" class="mt-1">
      <span class="text-error" v-if="v.required == false"
        >Please fill out.</span
      >
    </div>
  </div>
</template>

<script>
import TicketTemplate from "@/components/ticket/TicketTemplate";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  name: "text-editor-free",
  components: { OtherLoading, TicketTemplate },
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
      default: "editor",
    },
    value: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    defaultEdit: {
      required: false,
      type: Boolean,
      default: true,
    },
    showTemplate: {
      required: false,
      type: Boolean,
      default: false,
    },
    variables: {
      required: false,
      type: Array,
      default: () => [],
    },
    addFile: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editContent: "",

      editor: null,

      id: this.$route.params.id,
      isEdit: false,
      isLoading: false,
      templateId: 0,
    };
  },
  watch: {
    editContent(value) {
      return this.$emit("onDataChange", value);
    },
    value(val) {
      return (this.editContent = val);
    },
    // display(val) {
    //   if (val)
    //   console.log(val);
    // },
  },
  computed: {
    display() {
      if (this.defaultEdit) return this.isEdit;
      if (isNaN(this.id)) return true;
      return !this.id || this.id == 0 || this.isEdit;
    },
  },
  async mounted() {
    if (this.display) this.initEditor();
    this.editContent = this.value;
  },
  methods: {
    async initEditor() {
      var config = {};
      const $ = this;
      function convertFileToBase64(file, callback) {
        const reader = new FileReader();

        reader.onloadend = function () {
          // เมื่ออ่านไฟล์เสร็จ, reader.result จะเป็น Base64 string
          const base64String = reader.result;
          callback(base64String); // ส่ง base64 string ไปที่ callback
        };

        reader.onerror = function (error) {
          console.error("Error reading file:", error);
        };

        // อ่านไฟล์เป็น Data URL (Base64)
        reader.readAsDataURL(file);
      }
      config.file_upload_handler = function (
        file,
        callback,
        optionalIndex,
        optionalFiles
      ) {
        var uploadhandlerpath = `/News/UploadImage`; // ปรับเป็น path ของ API ของคุณ

        console.log("upload", file, "to", uploadhandlerpath);

        const controller = new AbortController();
        const signal = controller.signal;
        console.log(file);
        convertFileToBase64(file, function (base64String) {
          console.log("Base64 String:", base64String);
          // ส่ง base64String ไปที่ API หรือใช้งานตามต้องการ
          function detectMimeType(b64) {
            return b64.indexOf("image/png") != -1 ? 1 : 0;
          }
          let payload = {
            type: detectMimeType(base64String),
            Base64String: base64String,
          };
          $.axios
            .post(uploadhandlerpath, payload, {
              signal: signal,
            })
            .then((response) => {
              console.log("File uploaded to " + response.data.detail);
              callback(response.data.detail); // ส่ง URL กลับไป
            })
            .catch((error) => {
              console.log("Upload error", error);
              dialogouter.parentNode.removeChild(dialogouter);
              if (error.response) {
                callback(null, "http-error-" + error.response.status);
              } else if (uploadcancelled) {
                callback(null, "cancelled");
              } else {
                callback(null, error.message);
              }
            });
        });
      };

      config.toolbarfactory_myselect = function (cmd, suffix) {
        var sel = document.createElement("select");
        sel.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        function addOption(text, value) {
          var option = document.createElement("option");
          option.innerText = text;
          option.setAttribute("value", value);
          option.rawValue = value;
          sel.appendChild(option);
        }
        addOption("Variables");
        for (const element of $.variables) {
          addOption(element, element);
          // addOption("Blue content", "blue");
        }

        sel.onclick = function (e) {
          //the select will get focus , editor will lost focus
          e.stopPropagation(); //prevent editor get focus automatically
        };
        sel.onchange = function () {
          var option = sel.options[sel.selectedIndex];
          var val = option.rawValue;
          sel.selectedIndex = 0;
          editor.insertHTML(`{{${val}}}`);
        };
        return sel;
      };
      config.toolbar = "default";

      config.toolbar_default =
        "{bold,italic,underline,strike,superscript,subscript}|{fontname,fontsize}|{forecolor,backcolor}|{justifyleft,justifycenter,justifyright,justifyfull}|{indent,outdent,insertblockquote}|{insertorderedlist,insertunorderedlist,inserthorizontalrule,insertdate}|{code,fullscreenenter,fullscreenexit,selectall}|{undo,redo,insertlink,insertchars,inserttable,insertimage,insertcode,preview}";

      if (this.variables.length > 0) config.toolbar_default += " | {myselect}";

      const editor = await new window.RichTextEditor(`#${this.name}`, config);
      const defaultToolbar = editor;
      console.log(defaultToolbar);
      // editor.setConfig("toolbar", `{myselect}|` + defaultToolbar);
      this.editor = editor;

      editor.attachEvent("change", function () {
        $.$emit("onDataChange", editor.getHTMLCode());
      });
    },
    showEditor() {
      console.log("this.initEditor();");
      this.isEdit = true;
      this.$nextTick(() => {
        this.initEditor();
      });
    },
    onDataChange(val) {
      this.$emit("onDataChange", val);
    },
    selectTemplate(val) {
      this.templateId = val.id;
      this.editor.setHTMLCode(val.detail);
      this.$emit("onDataChange", val.detail);
    },
    addFileAttachment(list) {
      this.$emit("addFileAttachment", list);
    },

    clearFileAttachment() {
      console.log(this.$refs[this.name]);
      this.$refs[this.name].clearFile();
    },
  },
};
</script>

<style scoped>
.text-editor-custom {
  color: var(--font-main-color);
  font-size: 14px;
  margin-bottom: 2px;
}
/* .text-editor-custom.error {
  border: 2px solid red !important;
} */
.text-error {
  color: red;
}
::v-deep .error #quill-container {
  border-top: 1px;
  border-color: red;
}
.card-preview-editor {
  min-height: 350px;
  max-height: 400px;
  overflow: auto;
  position: relative;
  cursor: default;
}
.edit-detail {
  color: var(--primary-color);
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  z-index: 2;
}
.content-empty {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(245, 245, 245, 0.24);
  z-index: 1;
}
::v-deep .rte-modern.rte-desktop.rte-toolbar-default {
  min-width: unset;
}
</style>
