const BranchTier = {
  path: "",
  name: "",
  redirect: "branch-tier",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "branch-tier",
      name: "Branch Tier",
      redirect: "branch-tier",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/BranchSetting/BranchTier"
            ),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: ":id",
          name: "Branch Tier Detail",
          redirect: "/setting/branch-tier",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/BranchSetting/BranchTier/detail"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
      ],
    },
  ],
};
const BranchGroup = {
  path: "",
  name: "",
  redirect: "branch-group",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "branch-group",
      name: "Branch Group",
      redirect: "branch-group",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/BranchSetting/BranchGroup"
            ),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: ":id",
          name: "Branch Group Detail",
          redirect: "/setting/branch-group",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/BranchSetting/BranchGroup/detail"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
      ],
    },
  ],
};
const BranchStyle = {
  path: "",
  name: "",
  redirect: "branch-style",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "branch-style",
      name: "Branch Style",
      redirect: "branch-style",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import(
              "@/views/pages/setting/settingPage/BranchSetting/BranchStyle"
            ),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: ":id",
          name: "Branch Tier Detail",
          redirect: "/setting/branch-style",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/BranchSetting/BranchStyle/detail"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            // {
          ],
        },
      ],
    },
  ],
};
const BranchSetting = {
  path: "",
  name: "",
  redirect: "branch-setting",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "branch-setting",
      name: "Branch Setting",
      redirect: "branch-setting",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/BranchSetting"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        BranchTier,
        BranchStyle,
        BranchGroup,
      ],
    },
  ],
};
export default BranchSetting;
