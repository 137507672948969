<template>
  <div :class="['select-custom', { error: isValidate }, customClass]">
    <label v-if="title" class="main-label" :for="name">
      {{ title }}
      <span v-if="isRequired" class="text-error">*</span>
    </label>
    <br v-if="title" />
    <b-form-select
      v-bind:options="options"
      :ref="name"
      :name="name"
      :size="size"
      v-bind:value="value"
      :required="required"
      :class="['btn-select']"
      @change="onDataChange"
      @input="$emit('input', $event)"
      :valueField="valueField"
      :textField="textField"
      :disabled="disabled || readonly"
      :id="name"
      :disabled-field="disabledField"
    >
      <template #first>
        <slot name="option-first"></slot>
        <b-form-select-option v-if="noPleaseSelect && value === 0" :value="0"
          >Please select value.</b-form-select-option
        ><b-form-select-option
          v-if="noPleaseSelect && value === null"
          :value="null"
          >Please select value.</b-form-select-option
        ><b-form-select-option v-if="noPleaseSelect && value === ''" value=""
          >Please select value.</b-form-select-option
        >
      </template>
    </b-form-select>

    <div v-if="v && v.$error" class="mt-1">
      <span class="text-error" v-if="v.required == false">{{
        textValidate
      }}</span>
      <!-- <span class="text-error" v-else-if="v.minValue == false">{{options[0][textField || 'text']}}</span> -->
      <span class="text-error" v-else-if="v.minValueMain == false"
        >Please select value</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    options: {
      required: true,
      type: Array | undefined,
    },
    value: {
      required: false,
      type: Number | String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    valueField: {
      required: false,
      type: String,
    },
    textField: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    noPleaseSelect: {
      required: false,
      type: Boolean,
    },
    textValidate: {
      required: false,
      default: "Please select value.",
    },
    disabledField: {
      required: false,
      default: "disabled",
    },
    customClass: {
      required: false,
    },
    readonly: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      selected: 0,
    };
  },
  created() {},

  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event);
    },
  },
};
</script>

<style scoped lang="scss">
.select-custom {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.select-custom.error > .btn-select {
  border-color: red !important;
}

.select-custom > select {
  color: var(--font-main-color);

  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}
.select-custom > select:disabled {
  background-color: whitesmoke;
  cursor: default !important;
}
::v-deep .btn-select {
  color: #16274a;
  background-color: white;

  /* border-radius: 0px; */
  padding: 5px 10px;
  cursor: pointer;
  height: 32.8px;
}
::v-deep .btn-select.custom-select-lg {
  height: 45px;
  font-size: 1rem;
}
::v-deep .btn-select:focus {
  border-color: var(--primary-color);
}
::v-deep .btn-select > option:not(:disabled) {
  background-color: white;
  color: #16274a;
}
::v-deep .btn-select > option:checked {
  background-color: #16274a !important;
  color: white !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .select-custom {
    margin-top: 0;
  }
}
</style>
