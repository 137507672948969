<template>
  <div>
    <div class="ticket-panel">
      <div>
        <div class="d-flex align-items-center column-gap-lg">
          <!-- <div class="d-flex align-items-center"> -->
          <div class="action-button" @click="addAttachment" v-if="addFile">
            <font-awesome-icon
              icon="paperclip"
              title="ticket template"
              class="mr-2"
            />
            <span class="f-12"> Attachment</span>
          </div>
          <div class="action-button" @click="showTemplate">
            <font-awesome-icon
              icon="border-all"
              title="ticket template"
              class="mr-2"
            />
            <span class="f-12"> Template</span>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <template v-if="list.length > 0">
        <div class="text-bold mt-3">File Attachment</div>
        <div
          class="action-button mr-2"
          v-for="(file, index) of list"
          :key="file"
          @click.prevent="openFile(file.path)"
        >
          <font-awesome-icon icon="file" title="file attachment" class="mr-2" />
          <span class="f-12"> {{ file.name }}</span>
          <font-awesome-icon
            @click.prevent="deleteFile(index)"
            icon="times"
            title="delete file attachment"
            class="ml-2"
          />
        </div>
      </template>
    </div>

    <b-modal
      centered
      v-model="isShowTemplate"
      title="Ticket Template"
      size="lg"
      hide-footer
    >
      <TemplateList
        view
        @selectTemplate="(value, flag) => onSelectTemplate(value, flag)"
        :templateId="templateId"
      />
      <b-row>
        <b-col>
          <b-button variant="custom-outline-primary" @click="hideTemplate">
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <AddAttachment ref="attachment" @uploadFile="uploadFile" />
  </div>
</template>

<script>
import TemplateList from "@/views/pages/setting/settingPage/Ticket/Template";
import AddAttachment from "@/components/ticket/AddAttachment.vue";
export default {
  components: { TemplateList, AddAttachment },

  props: {
    templateId: { required: false },
    addFile: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowTemplate: false,
      list: [],
    };
  },
  methods: {
    showTemplate() {
      this.isShowTemplate = true;
    },
    hideTemplate() {
      this.isShowTemplate = false;
    },
    onSelectTemplate(value, flag) {
      if (flag) {
        value = { id: 0, detail: "" };
      }
      this.$emit("selectTemplate", value);
      this.hideTemplate();
    },
    addAttachment() {
      this.$refs.attachment.show();
    },
    uploadFile(file) {
      this.$refs.attachment.hide();

      this.list.push(file);
      this.$emit("onAddFileAttachment", this.list);
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    deleteFile(index) {
      this.list.splice(index, 1);
      this.$emit("onAddFileAttachment", this.list);
    },
    clearFile() {
      this.list = [];
    },
  },
};
</script>

<style lang="scss">
.ticket-panel {
  background-color: whitesmoke;
  padding: 0.5rem;
  div.action-button {
    color: black;
    background-color: rgb(225 225 225);
    // background-color: white;
    padding: 0.5rem;
    display: inline-flex;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
