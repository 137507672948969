<template>
  <div class="div-input" :class="className">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <div class="d-flex justify-content-between">
        <label v-if="textFloat" :class="labelClass">
          {{ textFloat }}
          <span v-if="isRequired" class="text-error">*</span>
        </label>
        <div class="count-characters ml-auto" v-if="maxCharacter || maxLength">
          {{ (value && value.length) || 0 | numeral("0,0") }}/{{
            maxCharacter || maxLength | numeral("0,0")
          }}
          Characters
        </div>
      </div>
      <textarea
        :class="['custom-input']"
        :placeholder="placeholder"
        :name="name"
        :rows="rows"
        :required="required"
        :value="value"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
        @change="onDataChange"
        @keyup="$emit('onKeyup', $event)"
        :oninput="oninput"
        :maxlength="maxLength"
      ></textarea>
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <span v-if="detail" class="text-desc pl-2">{{ detail }}</span>
    <div v-if="v && v.$error" class="mt-1">
      <span class="text-error" v-if="v.required == false"
        >Please input text.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: [String, Number],
    },
    rows: {
      required: false,
      type: [String, Number],
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    img: {
      required: false,
      type: String,
    },
    labelClass: {
      required: false,
      type: String,
      default: "",
    },
    oninput: {
      required: false,
      type: String,
    },
    maxCharacter: {
      required: false,
      type: [String, Number],
    },
    className: {
      required: false,
    },
    maxLength: {
      required: false,
      default: 255,
      type: Number,
    },
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
  },
};
</script>

<style scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}

.input-custom textarea {
  color: var(--font-main-color);
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
}
.input-custom textarea:focus {
  border: 1px solid #16274a;
}
.input-custom.error textarea {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 0.8rem;
  font-family: "Kanit-Light";
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.count-characters {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0px;
}
@media (max-width: 767.98px) {
  .input-custom label {
    font-size: 15px;
  }
}
</style>
