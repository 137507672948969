<template>
  <div>
    <div class="mt-3">
      <div class="my-2">
        <div class="title-msg mr-3">Variable</div>
        <template v-for="(variable, i) in variableItem">
          <b-dropdown
            :text="variable.name"
            variant="black"
            class="btn-color mr-2 mb-2 btn-outline automation"
            v-if="variable.is_branch"
            :key="i"
            right
            button-class="color-black"
          >
            <template v-for="branch_choice of variable.branch">
              <b-dropdown-item
                @click.prevent="AddVariable(branch_choice)"
                :key="branch_choice.name"
                >{{ branch_choice.display_name }}</b-dropdown-item
              ></template
            >
          </b-dropdown>
          <b-button
            v-else
            :key="i"
            class="btn-color mr-2 mb-2"
            variant="outline"
            @click.prevent="AddVariable(variable)"
          >
            {{ variable.name }}
          </b-button>
        </template>
        <template v-if="variableCampaignList.length > 0">
          <div class="title-msg mr-3">Form Variable</div>
          <template v-for="(variable, i) in variableCampaignList">
            <b-dropdown
              :text="variable.display_name"
              variant="black"
              class="btn-color mr-2 mb-2 btn-outline automation"
              v-if="variable.is_branch"
              :key="'form-' + i"
              right
              button-class="color-black"
            >
              <template v-for="branch_choice of variable.branch">
                <b-dropdown-item
                  @click.prevent="AddVariable(branch_choice)"
                  :key="branch_choice.name"
                  >{{ branch_choice.display_name }}</b-dropdown-item
                ></template
              >
            </b-dropdown>
            <b-button
              v-else
              :key="i"
              class="btn-color mr-2 mb-2"
              variant="outline"
              @click.prevent="AddVariable(variable)"
            >
              {{ variable.display_name }}
            </b-button>
          </template></template
        >
      </div>
    </div>
    <div class="div-input">
      <div class="display-only" v-if="isDisplay"></div>
      <div :class="['input-custom', { error: isValidate }]">
        <label>
          {{ textFloat }}
          <span v-if="isRequired" class="text-error">*</span>
        </label>
        <textarea
          :ref="reference"
          :class="['custom-input']"
          :placeholder="placeholder"
          :name="name"
          :rows="rows"
          :required="required"
          v-model="response.value"
          @input="$emit('input', $event.target.value)"
          @change="onDataChange"
          @keyup="$emit('onKeyup', $event)"
        ></textarea>
      </div>
      <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
      <span v-if="detail" class="text-desc pl-2">{{ detail }}</span>
      <div v-if="v && v.$error" class="mt-1">
        <span class="text-error" v-if="v.required == false"
          >This field can’t be empty</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: [String, Number],
    },
    rows: {
      required: false,
      type: [String, Number],
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    img: {
      required: false,
      type: String,
    },
    reference: {
      required: false,
      type: String,
    },
    variableItem: {
      required: true,
      type: Array,
    },
    variableCampaignList: {
      required: true,
      type: Array,
    },
    response: {
      required: false,
      type: Object,
    },
    formMain: {
      required: false,
      type: Object,
    },
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
    AddVariable(variable) {
      function removeBraces(text) {
        return text.replace(/[{}]/g, "");
      }
      const insertText = `{{${removeBraces(variable.name)}}}`;
      if (!insertText.length) return;
      const textarea = this.$refs.textarea;
      // this.$refs.textarea.focus();
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);
      this.response.value = before + `${insertText}` + after;
      this.formMain.is_not_variable = 0;
      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
  },
};
</script>

<style scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}

.input-custom > textarea {
  color: var(--font-main-color);
  background-color: white;
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
}
.input-custom > textarea:focus {
  border: 1px solid var(--font-main-color);
}
.input-custom.error > textarea {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: var(--font-main-color);
  font-size: 0.8rem;
  font-family: "Kanit-Light";
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
@media (max-width: 767.98px) {
}
.btn-outline {
  background-color: #cdcae2;
  border-radius: 50px;
  color: #885e9c;
}
.title-msg {
  color: #000;
}
::v-deep .btn-black {
  color: #885e9c;
}
</style>
